const SingleProduct = require("./SingleProduct");
const ProductMaterials = require("./Types/ProductMaterials");
const ProductTypes = require("./Types/ProductTypes");

class PoleProduct extends SingleProduct {
  name;
  article_id;
  description;
  workshop_naming;
  product_id;
  webshop_id;
  familyType;
  category;
  sub_category;
  selling_unit;
  min_quantity_surcharge;
  only_full_units;
  price;
  quantity = 1;
  discount = 0;

  is_aluminium;

  notes;

  length;

  productType = ProductTypes.POLE;

  calculated_selling_unit;

  passepartout_top_leg_width;
  passepartout_left_leg_width;
  passepartout_right_leg_width;
  passepartout_bottom_leg_width;

  discontinued = false;

  constructor(
    name,
    article_id,
    description,
    workshop_naming,
    product_id,
    webshop_id,
    familyType,
    category,
    sub_category,
    selling_unit,
    min_quantity_surcharge,
    only_full_units,
    price,
    quantity,
    price_unit,
    custom_pole_length,
    discount,
    notes,

    calculated_selling_unit,

    unavailable_regions,

    isRemainingStock,
    remainingStockDiscount,
    productImageUrls,
    remainingStockQuantity,

    discontinued
  ) {
    super();
    this.name = name;
    this.article_id = article_id;
    this.description = description;
    this.workshop_naming = workshop_naming;
    this.product_id = product_id;
    this.webshop_id = webshop_id;
    this.familyType = familyType;
    this.category = category;
    this.sub_category = sub_category;
    this.min_quantity_surcharge = min_quantity_surcharge;
    this.only_full_units = only_full_units === "Ja" ? true : false; //TODO: Implement into logic
    this.price = price;
    this.price_unit = price_unit;
    this.custom_pole_length = custom_pole_length;
    this.discount = discount;

    this.notes = notes;

    this.is_aluminium =
      this.category.includes(ProductMaterials.ALUMINIUM) ||
      this.sub_category.includes(ProductMaterials.ALUMINIUM);

    this.length = this.getLength();

    this.calculated_selling_unit = calculated_selling_unit;

    if (!this.calculated_selling_unit) {
      this.selling_unit = this.is_aluminium
        ? selling_unit / this.length
        : selling_unit / this.length;
      this.calculated_selling_unit = this.selling_unit;
    } else {
      this.selling_unit = this.calculated_selling_unit;
    }

    this.quantity = quantity ? quantity : 1;

    this.unavailable_regions = unavailable_regions;

    this.isRemainingStock = isRemainingStock;
    this.remainingStockDiscount = remainingStockDiscount;
    this.productImageUrls = productImageUrls;
    this.remainingStockQuantity = remainingStockQuantity;

    this.discontinued = discontinued === "True" ? true : false;
  }
  getLength() {
    return this.custom_pole_length
      ? this.custom_pole_length
      : this.is_aluminium
      ? 3.04
      : 3;
  }
  /*  reset() {
    this.quantity = 1;
    this.notes = undefined;
  } */
}
module.exports = PoleProduct;
