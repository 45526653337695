import ProductTypes from "../models/Types/ProductTypes";
import Denominations from "../models/Types/Denominations";
import FamilyTypes from "../models/Types/FamilyTypes";
import PassepartoutCutForms from "../models/Types/PassepartoutCutForms";
import Measurements from "@/models/Types/Measurements";
import PriceAdditions from "@/models/Pricing/PriceAdditions";
import PriceAdditions_BAUHAUS from "@/models/Pricing/BAUHAUS/PriceAdditions_BAUHAUS";
import PriceAdditions_XXXL from "@/models/Pricing/XXXL_GROUP/PriceAdditions_XXXL";
import ProductMaterials from "@/models/Types/ProductMaterials";
import { cloneDeep, isInteger } from "lodash";
import WorkshopFraming from "@/models/WorkshopFraming";
import SingleProduct from "@/models/SingleProduct";
import ProductOptionsToSort from "@/models/Types/ProductOptionsToSort";
import WorkshopServicePrices from "@/models/Types/WorkshopServicePrices";
import WorkshopServicePrices_BAUHAUS from "@/models/Types/BAUHAUS/WorkshopServicePrices_BAUHAUS";
import WorkshopServicePrices_XXXL from "@/models/Types/XXXL_GROUP/WorkshopServicePrices_XXXL"
import WorkshopStretching from "@/models/WorkshopStretching";
import WorkshopLamination from "@/models/WorkshopLamination";
import { SurfaceFinishingPrices } from "@/models/Types/WorkshopSurfaceFinishings";
import { SurfaceFinishingPrices_BAUHAUS } from "@/models/Types/BAUHAUS/WorkshopSurfaceFinishings_BAUHAUS";
import { SurfaceFinishingPrices_XXXL } from "@/models/Types/XXXL_GROUP/WorkshopSurfaceFinishings_XXXL";
import store from "@/main";
import UserTypeProvider from "./UserTypeProvider";
import UserTypes from "@/models/Types/UserTypes";
import WorkshopModes from "@/models/Types/WorkshopModes";

const userType = UserTypeProvider.getUserType()
const DEBUG = process.env.VUE_APP_ENABLE_DEBUG === 'true'; // Enable or disable debugging here
let priceAdditions = PriceAdditions;
let workshopServicePrices = WorkshopServicePrices;
let surfaceFinishingPrices = SurfaceFinishingPrices;
let CANVAS_STRETCH_BASE_PRICING = "90x120:11.2,200x200:18.3";
if (userType === UserTypes.RETAILER) {
  CANVAS_STRETCH_BASE_PRICING = "90x120:11.2,200x200:18.3";
} else if (userType === UserTypes.BAUHAUS) {
  CANVAS_STRETCH_BASE_PRICING = "90x120:22.52,200x200:22.52";
  priceAdditions = PriceAdditions_BAUHAUS;
  workshopServicePrices = WorkshopServicePrices_BAUHAUS;
  surfaceFinishingPrices = SurfaceFinishingPrices_BAUHAUS;
} else if (userType === UserTypes.XXXL_GROUP) {
  CANVAS_STRETCH_BASE_PRICING = "90x120:29.65,200x200:29.65";
  priceAdditions = PriceAdditions_XXXL;
  workshopServicePrices = WorkshopServicePrices_XXXL;
  surfaceFinishingPrices = SurfaceFinishingPrices_XXXL;
}

function calculatePrice(passedProduct, ignoreMinQuantity) {
  let price = 0;
  const user = JSON.parse(
    localStorage.getItem("ibisOnlinebestellungUserObject")
  );
  let product = cloneDeep(passedProduct);

  if (DEBUG) console.log(`Initial Price: ${price}`);
  if (product instanceof WorkshopFraming) {

    if (product.frame) {
      shiftToMinimumCalculationDimensions(product.frame)
      shiftToMinimumCalculationDimensions(product.passepartout)
      shiftToMinimumCalculationDimensions(product.glas)
      shiftToMinimumCalculationDimensions(product.second_glas)
      shiftToMinimumCalculationDimensions(product.back_wall)
      shiftToMinimumCalculationDimensions(product.stretcher_frame)
      shiftToMinimumCalculationDimensions(product.carrier_material)

      if (DEBUG) console.log("Calculating price for RAHMEN");
      let framePrice = calculatePrice(product.frame, true);
      if (DEBUG) console.log(`Frame Price: ${framePrice}`);
      price += framePrice;
      if (DEBUG) console.log(`Price after adding frame: ${price}`);
      if (product.frame.sub_category === ProductOptionsToSort.ALU_CUT) {
        let aluCutPrice =
          (product.frame.width / 100) * 2 * workshopServicePrices.VM33000 +
          (product.frame.height / 100) * 2 * workshopServicePrices.VM33000;
        price += aluCutPrice;
        if (DEBUG) console.log(`ALU_CUT Price: ${aluCutPrice}`);
        if (DEBUG) console.log(`Price after ALU_CUT: ${price}`);
      }
    }
    if (product.passepartout && !product.no_passepartout) {
      if (DEBUG) console.log("Calculating price for PP");
      let passepartoutPrice = calculatePrice(product.passepartout, true);
      if (DEBUG) console.log(`Passepartout Price: ${passepartoutPrice}`);
      price += passepartoutPrice;
      if (DEBUG) console.log(`Price after adding passepartout: ${price}`);
    }
    if (product.double_passepartout && !product.no_passepartout && product.second_passepartout) {
      if (DEBUG) console.log("Calculating price for SECOND_PP");
      let secondPassepartoutPrice = calculatePrice(product.second_passepartout, true);
      if (DEBUG) console.log(`Second Passepartout Price: ${secondPassepartoutPrice}`);
      price += secondPassepartoutPrice;
      if (DEBUG) console.log(`Price after adding second passepartout: ${price}`);
    }
    if (product.glas) {
      if (DEBUG) console.log("Calculating price for GLAS");
      let glasPrice = calculatePrice(product.glas, true);
      if (DEBUG) console.log(`Glas Price: ${glasPrice}`);
      price += glasPrice;
      if (DEBUG) console.log(`Price after adding glas: ${price}`);
    }
    if (product.second_glas) {
      if (DEBUG) console.log("Calculating price for SECOND_GLAS");
      let secondGlasPrice = calculatePrice(product.second_glas, true);
      if (DEBUG) console.log(`Second Glas Price: ${secondGlasPrice}`);
      price += secondGlasPrice;
      if (DEBUG) console.log(`Price after adding second glas: ${price}`);
    }
    if (product.back_wall) {
      if (DEBUG) console.log("Calculating price for RÜCKWAND");
      let backWallPrice = calculatePrice(product.back_wall, true);
      if (DEBUG) console.log(`Back Wall Price: ${backWallPrice}`);
      price += backWallPrice;
      if (DEBUG) console.log(`Price after adding back wall: ${price}`);
    }

    if (product.stretcher_frame && product.stretcher_frame.price > 0) {
      if (DEBUG) console.log("Calculating price for KEILRAHMEN");
      let stretcherFramePrice = calculatePrice(product.stretcher_frame, true);
      price += stretcherFramePrice;
      if (DEBUG) console.log(`Stretcher Frame Price: ${stretcherFramePrice}`);
      const dimensions = parsePricingStrings(CANVAS_STRETCH_BASE_PRICING);
      const dimensionPriceFactor = getPriceForDimensions(
        dimensions,
        product.stretcher_frame
      );
      const dimensionPrice = dimensionPriceFactor;
      if (dimensionPrice) {
        let dimensionPriceAddition =
          (product.stretcher_frame.width / 100) * 2 * dimensionPrice +
          (product.stretcher_frame.height / 100) * 2 * dimensionPrice;
        price += dimensionPriceAddition;
        if (DEBUG) console.log(`Dimension Price Addition: ${dimensionPriceAddition}`);
      }
      if (DEBUG) console.log(`Price after stretcher_frame: ${price}`);
    }

    if (product.carrier_material) {
      if (DEBUG) console.log("Calculating price for CARRIER_MATERIAL");
      let carrierMaterialPrice = calculatePrice(product.carrier_material, true);
      if (DEBUG) console.log(`Carrier Material Price: ${carrierMaterialPrice}`);
      price += carrierMaterialPrice;
      if (DEBUG) console.log(`Price after adding carrier material: ${price}`);
      const dimensions = parsePricingStrings(product.stretching_type?.pricing);
      const dimensionPriceFactor = getPriceForDimensions(
        dimensions,
        product.carrier_material
      );
      const dimensionPrice = dimensionPriceFactor;
      if (
        dimensionPrice &&
        product.carrier_material.height &&
        product.carrier_material.width
      ) {
        let dimensionPriceAddition =
          ((product.carrier_material.height / 100) * 2 +
            (product.carrier_material.width / 100) * 2) *
          dimensionPrice *
          product.quantity;
        price += dimensionPriceAddition;
        if (DEBUG) console.log(`Dimension Price Addition: ${dimensionPriceAddition}`);
        if (DEBUG) console.log(`Price after adding dimension price: ${price}`);
      }
    }
    if (product.surface_finishing) {
      if (DEBUG) console.log("Calculating price for SURFACE_FINISHING");
      const dimensions = parsePricingStrings(surfaceFinishingPrices);
      if (dimensions) {
        const dimensionPriceFactor = getPriceForDimensions(
          dimensions,
          product.carrier_material
        );
        if (dimensionPriceFactor && product.carrier_material) {
          let surfaceFinishingPrice =
            (product.carrier_material.width / 100) * 2 * dimensionPriceFactor +
            (product.carrier_material.height / 100) * 2 * dimensionPriceFactor;
          price += surfaceFinishingPrice;
          if (DEBUG) console.log(`Surface Finishing Price: ${surfaceFinishingPrice}`);
          if (DEBUG) console.log(`Price after adding surface finishing: ${price}`);
        }
      }
    }

    if (product.hangings.length) {
      let multiplikator = 1;
      if (product.frame) {
        if (
          (product.frame.height / 100) * 2 + (product.frame.width / 100) * 2 >=
          5
        )
          multiplikator = 1.1;
      }
      product.hangings.forEach(
        (hanging) => {
          let hangingPrice = hanging.price * multiplikator;
          price += hangingPrice;
          if (DEBUG) console.log(`Hanging Price: ${hangingPrice}`);
        }
      );
      if (DEBUG) console.log(`Price after adding hangings: ${price}`);
    }
    if (product.installation && product.frame && !product.already_clamped && product.mode !== WorkshopModes.FRAME_WITH_EQUIPMENT) {
      let multiplikator = 1;
      if (product.frame) {
        if (
          (product.frame.height / 100) * 2 + (product.frame.width / 100) * 2 >=
          5
        )
          multiplikator = 1.1;
      }
      let installationPrice =
        ((product.frame.selected_measurement_type === Measurements.FALZMASS
          ? product.frame.width - product.folding_allowance
          : product.frame.width) /
          100) *
        2 *
        product.installation.price *
        multiplikator +
        ((product.frame.selected_measurement_type === Measurements.FALZMASS
          ? product.frame.height - product.folding_allowance
          : product.frame.height) /
          100) *
        2 *
        product.installation.price *
        multiplikator;
      price += installationPrice;
      if (DEBUG) console.log(`Installation Price: ${installationPrice}`);
      if (DEBUG) console.log(`Price after adding installation: ${price}`);
    }
    if (product.services) {
      let multiplikator = 1;
      if (product.frame) {
        if (
          (product.frame.height / 100) * 2 + (product.frame.width / 100) * 2 >=
          5
        )
          multiplikator = 1.1;
      }
      product.services.forEach((service) => {
        let servicePrice = 0;
        if (service.pricePerWalkingMeters && product.frame) {
          servicePrice =
            (product.frame.width / 100) * 2 * service.price * multiplikator +
            (product.frame.height / 100) * 2 * service.price * multiplikator;
        } else {
          servicePrice = service.price * multiplikator;
        }
        price += servicePrice;
        if (DEBUG) console.log(`Service Price: ${servicePrice}`);
      });
      if (DEBUG) console.log(`Price after adding services: ${price}`);
    }
    if (DEBUG) console.log(`Total price before discounts: ${price}`);

    let discountCodePercentage = 0;
    if (store.getters.currentDiscountCode) {
      discountCodePercentage = store.getters.currentDiscountCode
        .discountPercentage
        ? store.getters.currentDiscountCode.discountPercentage
        : 0;
    }
    let absoluteDiscount = 0;
    if (store.getters.currentDiscountCode) {
      absoluteDiscount = store.getters.currentDiscountCode.absoluteDiscount
        ? store.getters.currentDiscountCode.absoluteDiscount
        : 0;
    }
    if (discountCodePercentage > 0 && !ignoreMinQuantity) {
      let finalPrice = price * user.multiplikator * (1 - discountCodePercentage);
      if (DEBUG) console.log(`Final price after discountCodePercentage:${finalPrice * product.quantity}`);
      return finalPrice * product.quantity;
    } else if (absoluteDiscount > 0 && !ignoreMinQuantity) {
      let finalPrice = price * user.multiplikator - absoluteDiscount;
      if (DEBUG) console.log(`Final price after absoluteDiscount: ${finalPrice * product.quantity}`);
      return finalPrice * product.quantity;
    } else {
      if (product.discount && !ignoreMinQuantity) {
        let finalPrice = price * (1 - product.discount) * user.multiplikator;
        if (DEBUG) console.log(`Final price after product discount: ${finalPrice * product.quantity}`);
        return finalPrice * product.quantity;
      } else {
        let finalPrice = price * user.multiplikator;
        if (DEBUG) console.log(`Final price: ${finalPrice * product.quantity}`);
        return finalPrice * product.quantity;
      }
    }
  } else if (product instanceof WorkshopStretching) {

    shiftToMinimumCalculationDimensions(product.stretcher_frame)


    if (DEBUG) console.log("Calculating price for WorkshopStretching");
    if (product.stretcher_frame && product.stretcher_frame.price > 0) {
      let stretcherFramePrice = calculatePrice(product.stretcher_frame, true);
      price += stretcherFramePrice;
      if (DEBUG) console.log(`Stretcher Frame Price: ${stretcherFramePrice}`);
      const dimensions = parsePricingStrings(CANVAS_STRETCH_BASE_PRICING);
      const dimensionPriceFactor = getPriceForDimensions(
        dimensions,
        product.stretcher_frame
      );
      const dimensionPrice = dimensionPriceFactor;
      if (dimensionPrice) {
        let dimensionPriceAddition =
          (product.stretcher_frame.width / 100) * 2 * dimensionPrice +
          (product.stretcher_frame.height / 100) * 2 * dimensionPrice;
        price += dimensionPriceAddition;
        if (DEBUG) console.log(`Dimension Price Addition: ${dimensionPriceAddition}`);
      }
      if (DEBUG) console.log(`Price after stretcher_frame: ${price}`);
    }
    if (product.hangings.length) {
      let multiplikator = 1;
      if (product.stretcher_frame) {
        if (
          (product.stretcher_frame.height / 100) * 2 + (product.stretcher_frame.width / 100) * 2 >=
          5
        )
          multiplikator = 1.1;
      }
      product.hangings.forEach(
        (hanging) => {
          let hangingPrice = hanging.price * multiplikator;
          price += hangingPrice;
          if (DEBUG) console.log(`Hanging Price: ${hangingPrice}`);
        }
      );
      if (DEBUG) console.log(`Price after hangings: ${price}`);
    }

    if (product.services) {
      let multiplikator = 1;
      if (product.stretcher_frame) {
        if (
          (product.stretcher_frame.height / 100) * 2 + (product.stretcher_frame.width / 100) * 2 >=
          5
        )
          multiplikator = 1.1;
      }
      product.services.forEach((service) => {
        let servicePrice = 0;
        if (service.pricePerWalkingMeters && product.stretcher_frame) {
          servicePrice =
            (product.stretcher_frame.width / 100) * 2 * service.price * multiplikator +
            (product.stretcher_frame.height / 100) * 2 * service.price * multiplikator;
        } else {
          servicePrice = service.price * multiplikator;
        }
        price += servicePrice;
        if (DEBUG) console.log(`Service Price: ${servicePrice}`);
      });
      if (DEBUG) console.log(`Price after services: ${price}`);
    }
    let discountCodePercentage = 0;
    if (store.getters.currentDiscountCode) {
      discountCodePercentage = store.getters.currentDiscountCode
        .discountPercentage
        ? store.getters.currentDiscountCode.discountPercentage
        : 0;
    }
    let absoluteDiscount = 0;
    if (store.getters.currentDiscountCode) {
      absoluteDiscount = store.getters.currentDiscountCode.absoluteDiscount
        ? store.getters.currentDiscountCode.absoluteDiscount
        : 0;
    }
    if (discountCodePercentage > 0 && !ignoreMinQuantity) {
      let finalPrice = price * user.multiplikator * (1 - discountCodePercentage);
      if (DEBUG) console.log(`Final price after discountCodePercentage: ${finalPrice * product.quantity}`);
      return finalPrice * product.quantity;
    } else if (absoluteDiscount > 0 && !ignoreMinQuantity) {
      let finalPrice = price * user.multiplikator - absoluteDiscount;
      if (DEBUG) console.log(`Final price after absoluteDiscount: ${finalPrice * product.quantity}`);
      return finalPrice * product.quantity;
    } else {
      if (product.discount && !ignoreMinQuantity) {
        let finalPrice = price * (1 - product.discount) * user.multiplikator;
        if (DEBUG) console.log(`Final price after product discount: ${finalPrice * product.quantity}`);
        return finalPrice * product.quantity;
      } else {
        let finalPrice = price * user.multiplikator;
        if (DEBUG) console.log(`Final price: ${finalPrice * product.quantity}`);
        return finalPrice * product.quantity;
      }
    }
  } else if (product instanceof WorkshopLamination) {

    shiftToMinimumCalculationDimensions(product.carrier_material)

    if (DEBUG) console.log("Calculating price for WorkshopLamination");
    if (product.carrier_material) {
      let carrierMaterialPrice = calculatePrice(product.carrier_material, true);
      price += carrierMaterialPrice;
      if (DEBUG) console.log(`Carrier Material Price: ${carrierMaterialPrice}`);
      const dimensions = parsePricingStrings(product.stretching_type?.pricing);
      const dimensionPriceFactor = getPriceForDimensions(
        dimensions,
        product.carrier_material
      );
      const dimensionPrice = dimensionPriceFactor;
      if (
        dimensionPrice &&
        product.carrier_material.height &&
        product.carrier_material.width
      ) {
        let dimensionPriceAddition =
          ((product.carrier_material.height / 100) * 2 +
            (product.carrier_material.width / 100) * 2) *
          dimensionPrice *
          product.quantity;
        price += dimensionPriceAddition;
        if (DEBUG) console.log(`Dimension Price Addition: ${dimensionPriceAddition}`);
        if (DEBUG) console.log(`Price after carrier_material: ${price}`);
      }
    }
    if (product.surface_finishing) {
      const dimensions = parsePricingStrings(surfaceFinishingPrices);
      if (dimensions) {
        const dimensionPriceFactor = getPriceForDimensions(
          dimensions,
          product.carrier_material
        );
        if (dimensionPriceFactor && product.carrier_material) {
          let surfaceFinishingPrice =
            (product.carrier_material.width / 100) * 2 * dimensionPriceFactor +
            (product.carrier_material.height / 100) * 2 * dimensionPriceFactor;
          price += surfaceFinishingPrice;
          if (DEBUG) console.log(`Surface Finishing Price: ${surfaceFinishingPrice}`);
          if (DEBUG) console.log(`Price after surface_finishing: ${price}`);
        }
      }
    }
    if (product.hangings.length) {
      let multiplikator = 1;
      if (product.carrier_material) {
        if (
          (product.carrier_material.height / 100) * 2 + (product.carrier_material.width / 100) * 2 >=
          5
        )
          multiplikator = 1.1;
      }
      product.hangings.forEach(
        (hanging) => {
          let hangingPrice = hanging.price * multiplikator;
          price += hangingPrice;
          if (DEBUG) console.log(`Hanging Price: ${hangingPrice}`);
        }
      );
      if (DEBUG) console.log(`Price after hangings: ${price}`);
    }
    if (product.services) {
      let multiplikator = 1;
      if (product.carrier_material) {
        if (
          (product.carrier_material.height / 100) * 2 + (product.carrier_material.width / 100) * 2 >=
          5
        )
          multiplikator = 1.1;
      }
      product.services.forEach((service) => {
        let servicePrice = 0;
        if (service.pricePerWalkingMeters && product.carrier_material) {
          servicePrice =
            (product.carrier_material.width / 100) * 2 * service.price * multiplikator +
            (product.carrier_material.height / 100) * 2 * service.price * multiplikator;
        } else {
          servicePrice = service.price * multiplikator;
        }
        price += servicePrice;
        if (DEBUG) console.log(`Service Price: ${servicePrice}`);
      });
      if (DEBUG) console.log(`Price after services: ${price}`);
    }
    let discountCodePercentage = 0;
    if (store.getters.currentDiscountCode) {
      discountCodePercentage = store.getters.currentDiscountCode
        .discountPercentage
        ? store.getters.currentDiscountCode.discountPercentage
        : 0;
    }
    let absoluteDiscount = 0;
    if (store.getters.currentDiscountCode) {
      absoluteDiscount = store.getters.currentDiscountCode.absoluteDiscount
        ? store.getters.currentDiscountCode.absoluteDiscount
        : 0;
    }
    if (discountCodePercentage > 0 && !ignoreMinQuantity) {
      let finalPrice = price * user.multiplikator * (1 - discountCodePercentage);
      if (DEBUG) console.log(`Final price after discountCodePercentage: ${finalPrice * product.quantity}`);
      return finalPrice * product.quantity;
    } else if (absoluteDiscount > 0 && !ignoreMinQuantity) {
      let finalPrice = price * user.multiplikator - absoluteDiscount;
      if (DEBUG) console.log(`Final price after absoluteDiscount: ${finalPrice * product.quantity}`);
      return finalPrice * product.quantity;
    } else {
      if (product.discount && !ignoreMinQuantity) {
        let finalPrice = price * (1 - product.discount) * user.multiplikator;
        if (DEBUG) console.log(`Final price after product discount: ${finalPrice * product.quantity}`);
        return finalPrice * product.quantity;
      } else {
        let finalPrice = price * user.multiplikator;
        if (DEBUG) console.log(`Final price: ${finalPrice * product.quantity}`);
        return finalPrice * product.quantity;
      }
    }
  } else if (product instanceof SingleProduct) {
    if (DEBUG) console.log("Calculating price for SingleProduct");
    if (product.productType === ProductTypes.POLE) {
      let polePrice = product.length * product.quantity * product.price;
      price += polePrice;
      if (DEBUG) console.log(`Pole Price: ${polePrice}`);
      if (DEBUG) console.log(`Price after POLE: ${price}`);
    } else if (product.productType === ProductTypes.CUT) {
      let cutProductPrice = 0;

      if (product.familyType !== FamilyTypes.GLAS) {
        if (product.width && product.height) {
          if (product.familyType === FamilyTypes.STRETCHER_FRAME) {
            let priceToUse;
            if ((!isInteger(product.width) || !isInteger(product.height)) && product.sf_decimal_size_price && UserTypeProvider.getUserType() === UserTypes.RETAILER) {
              priceToUse = product.sf_decimal_size_price;
            } else {
              priceToUse = product.price;
            }
            if (product.sf_with_bridge || product.sf_with_cross) {
              let biggerSide;
              let smallerSide;

              if (product.width > product.height) {
                biggerSide = product.width;
                smallerSide = product.height;
              } else if (product.width < product.height) {
                biggerSide = product.height;
                smallerSide = product.width;
              } else {
                biggerSide = product.width;
                smallerSide = product.width;
              }
              if (product.sf_with_bridge) {
                let bridgePrice =
                  (biggerSide / 100) * 2 * priceToUse * product.quantity +
                  (smallerSide / 100) * 3 * priceToUse * product.quantity;
                cutProductPrice += bridgePrice;
                if (DEBUG) console.log(`Bridge Price: ${bridgePrice}`);
              } else if (product.sf_with_cross) {
                let crossPrice =
                  (biggerSide / 100) * 3 * priceToUse * product.quantity +
                  (smallerSide / 100) * 3 * priceToUse * product.quantity;
                cutProductPrice += crossPrice;
                if (DEBUG) console.log(`Cross Price: ${crossPrice}`);
              }
            } else {
              let basicPrice =
                (product.width / 100) * 2 * priceToUse * product.quantity +
                (product.height / 100) * 2 * priceToUse * product.quantity;
              cutProductPrice += basicPrice;
              if (DEBUG) console.log(`Basic Price: ${basicPrice}`);
            }
            if (product.height * 2 + product.width * 2 >= 500) {
              cutProductPrice *= 1.1;
              if (DEBUG) console.log(`Cut Product Price after 500+ size factor: ${cutProductPrice}`);
            }
          } else {
            let basicPrice =
              (product.width / 100) * 2 * product.price * product.quantity +
              (product.height / 100) * 2 * product.price * product.quantity;
            cutProductPrice += basicPrice;
            if (DEBUG) console.log(`Basic Price: ${basicPrice}`);

            if (product.height >= 180 || product.width >= 180) {
              cutProductPrice *= 1.1;
              if (DEBUG) console.log(`Cut Product Price after 180+ size factor: ${cutProductPrice}`);
            } else if (product.height * 2 + product.width * 2 >= 500) {
              cutProductPrice *= 1.1;
              if (DEBUG) console.log(`Cut Product Price after 500+ size factor: ${cutProductPrice}`);
            }
          }
        } else if (product.default_width && product.default_height) {
          let defaultPrice =
            (product.default_width / 100) * 2 * product.price * product.quantity +
            (product.default_height / 100) * 2 * product.price * product.quantity;
          cutProductPrice += defaultPrice;
          if (DEBUG) console.log(`Default Price: ${defaultPrice}`);
        }
        if (
          (product.width * 2 + product.height * 2) / 100 < 1 &&
          product.selected_denomination_type !== Denominations.LEGS &&
          !product.qm_price
        ) {
          cutProductPrice = product.price * product.quantity;
          if (DEBUG) console.log(`Cut Product Price after small size factor: ${cutProductPrice}`);
        }
        if (product.familyType === FamilyTypes.PASSEPARTOUT) {
          if (
            product.passepartout_outer_cut_form === PassepartoutCutForms.OVAL ||
            product.passepartout_outer_cut_form ===
            PassepartoutCutForms.ROUND ||
            (!product.passepartout_no_cut_out &&
              (product.passepartout_inner_cut_form ===
                PassepartoutCutForms.OVAL ||
                product.passepartout_inner_cut_form ===
                PassepartoutCutForms.ROUND))
          ) {
            let customFormPrice = priceAdditions.PP_CUSTOM_FORM_CONSTANT * product.quantity;
            cutProductPrice += customFormPrice;
            if (DEBUG) console.log(`Custom Form Price: ${customFormPrice}`);
          }

          let additionalCutOutPrice = priceAdditions.PASSEPARTOUT_ADDITIONAL_CUT_OUT * (product.passepartout_number_of_cut_outs - 1);
          cutProductPrice += additionalCutOutPrice;
          if (DEBUG) console.log(`Additional Cut Out Price: ${additionalCutOutPrice}`);
        } else {
          if (product.selected_denomination_type === Denominations.LEGS) {
            let legsPrice = product.width
              ? (product.width / 100) * product.price * product.quantity
              : 0;
            cutProductPrice = legsPrice;
            //cutProductPrice += legsPrice;
            //cutProductPrice *= product.quantity * 0.25;
            if (DEBUG) console.log(`Legs Price: ${legsPrice}`);
          }

          if (product.is_aluminium && product.with_poly) {
            if (
              Object.prototype.hasOwnProperty.call(
                priceAdditions.POLY_GROUP_CONSTANTS,
                product.mounting_set
              )
            ) {
              let polyPrice = priceAdditions.POLY_GROUP_CONSTANTS[product.mounting_set] * product.quantity;
              cutProductPrice += polyPrice;
              if (DEBUG) console.log(`Poly Price: ${polyPrice}`);
            }
          }
          if (
            product.familyType !== FamilyTypes.GLAS &&
            product.sub_category !== ProductOptionsToSort.BACK_WALL_CUT &&
            (product.selected_measurement_type === Measurements.AUSSENMASS ||
              product.selected_measurement_type === Measurements.LICHTMASS)
          ) {
            cutProductPrice *= priceAdditions.CUSTOM_MEASUREMENT_TYPE_FACTOR;
            if (DEBUG) console.log(`Cut Product Price after custom measurement type factor: ${cutProductPrice}`);
          }
        }
      } else {
        if (product.width && product.height && !product.qm_price) {
          const dimensions = parsePricingStrings(product.price?.toString());
          const dimensionPriceFactor = getPriceForDimensions(
            dimensions,
            product
          );
          const dimensionPrice = dimensionPriceFactor
            ? dimensionPriceFactor
            : product.price;
          let dimensionPriceAddition =
            (product.width / 100) * dimensionPrice * product.quantity * 2 +
            (product.height / 100) * dimensionPrice * product.quantity * 2;
          cutProductPrice += dimensionPriceAddition;
          if (DEBUG) console.log(`Dimension Price Addition: ${dimensionPriceAddition}`);

          if (
            (product.width * 2 + product.height * 2) / 100 < 1 &&
            product.selected_denomination_type !== Denominations.LEGS &&
            !product.qm_price
          ) {
            const dimensionPriceFactor = getPriceForDimensions(
              dimensions,
              product
            );
            cutProductPrice = dimensionPriceFactor
              ? dimensionPriceFactor
              : product.price * product.quantity;
            if (DEBUG) console.log(`Cut Product Price after small size factor: ${cutProductPrice}`);
          }
        } else if (product.width && product.height && product.qm_price) {
          const dimensions = product.price?.toString().includes(":")
            ? parsePricingStrings(product.price)
            : undefined;
          let qmPriceAddition =
            (product.width / 100) *
            (product.height / 100) *
            product.qm_price *
            product.quantity;
          cutProductPrice += qmPriceAddition;
          if (DEBUG) console.log(`QM Price Addition: ${qmPriceAddition}`);
          if (
            (product.width * 2 + product.height * 2) / 100 < 1 &&
            product.selected_denomination_type !== Denominations.LEGS &&
            !product.qm_price
          ) {
            cutProductPrice = dimensions
              ? getPriceForDimensions(dimensions, product)
              : product.price * product.quantity;
            if (DEBUG) console.log(`Cut Product Price after small size factor: ${cutProductPrice}`);
          }

          const max_price = dimensions
            ? getPriceForDimensions(dimensions, product)
            : product.max_price;

          if (cutProductPrice > max_price * product.quantity) {
            cutProductPrice = max_price * product.quantity;
            if (DEBUG) console.log(`Cut Product Price after max price cap: ${cutProductPrice}`);
          }
        }
      }

      price += cutProductPrice;
      if (DEBUG) console.log(`Price after CUT: ${price}`);
    } else if (product.productType === ProductTypes.FRAME) {
      if (product.selected_measurement_type === Measurements.BILDMASS) {
        product.width += 0.2;
        product.height += 0.2;
      }
      if (product.width && product.height && product.price > 0) {
        let framePrice =
          (product.width / 100) * 2 * product.price * product.quantity +
          (product.height / 100) * 2 * product.price * product.quantity;
        price += framePrice;
        if (DEBUG) console.log(`Frame Price: ${framePrice}`);
        if ((product.width * 2 + product.height * 2) / 100 < 1) {
          price = product.price * product.quantity;
          if (DEBUG) console.log(`Price after small size factor: ${price}`);
        }
        if (product.sub_category.includes(ProductMaterials.WOOD)) {
          let woodAddition = 0;
          if (
            (product.width < 40 && product.height < 50) ||
            (product.width < 50 && product.height < 40)
          ) {
            if (UserTypeProvider.getUserType() === UserTypes.RETAILER) {
              woodAddition = priceAdditions.FRAME_MANUFACTORING_SMALL_CONSTANT *
                product.quantity;
            } else {
              woodAddition =
                (product.width / 100) * 2 * priceAdditions.FRAME_MANUFACTORING_SMALL_CONSTANT * product.quantity +
                (product.height / 100) * 2 * priceAdditions.FRAME_MANUFACTORING_SMALL_CONSTANT * product.quantity;
            }
          } else if (
            (product.width < 70 && product.height < 100) ||
            (product.width < 100 && product.height < 70)
          ) {
            if (UserTypeProvider.getUserType() === UserTypes.RETAILER) {
              woodAddition = priceAdditions.FRAME_MANUFACTORING_MEDIUM_CONSTANT *
                product.quantity;
            } else {
              woodAddition =
                (product.width / 100) * 2 * priceAdditions.FRAME_MANUFACTORING_MEDIUM_CONSTANT * product.quantity +
                (product.height / 100) * 2 * priceAdditions.FRAME_MANUFACTORING_MEDIUM_CONSTANT * product.quantity;
            }
          } else {
            if (UserTypeProvider.getUserType() === UserTypes.RETAILER) {
              woodAddition = priceAdditions.FRAME_MANUFACTORING_LARGE_CONSTANT *
                product.quantity;
            } else {
              woodAddition =
                (product.width / 100) * 2 * priceAdditions.FRAME_MANUFACTORING_LARGE_CONSTANT * product.quantity +
                (product.height / 100) * 2 * priceAdditions.FRAME_MANUFACTORING_LARGE_CONSTANT * product.quantity;
            }
          }
          price += woodAddition;
          if (DEBUG) console.log(`Wood Addition: ${woodAddition}`);
          if (!product.no_hangers) {
            let hangersAddition = priceAdditions.FRAME_HANGERS * product.quantity;
            price += hangersAddition;
            if (DEBUG) console.log(`Hangers Addition: ${hangersAddition}`);
          }
        }
        if (
          product.selected_measurement_type === Measurements.AUSSENMASS ||
          product.selected_measurement_type === Measurements.LICHTMASS
        ) {
          price *= priceAdditions.CUSTOM_MEASUREMENT_TYPE_FACTOR;
          if (DEBUG) console.log(`Price after custom measurement type factor: ${price}`);
        }
        if (product.height >= 180 || product.width >= 180) {
          price *= 1.1;
          if (DEBUG) console.log(`Price after 180+ size factor: ${price}`);
        } else if (product.height * 2 + product.width * 2 >= 500) {
          price *= 1.1;
          if (DEBUG) console.log(`Price after 500+ size factor: ${price}`);
        }
        if (DEBUG) console.log(`Price after FRAME: ${price}`);
      } else {
        price = 0;
        if (DEBUG) console.log(`Price set to 0 due to missing width/height or invalid price`);
      }
    } else if (product.productType === ProductTypes.GLAS_BOARD) {
      if (product.qm_price) {
        if (product.width && product.height) {
          let glasBoardPrice =
            (product.width / 100) *
            (product.height / 100) *
            product.qm_price *
            product.quantity;
          price += glasBoardPrice;
          if (DEBUG) console.log(`GLAS_BOARD Price: ${glasBoardPrice}`);
        }
      } else if (!product.qm_price) {
        let glasBoardPrice = product.price * product.quantity;
        price += glasBoardPrice;
        if (DEBUG) console.log(`GLAS_BOARD Price: ${glasBoardPrice}`);
      } else {
        price = 0;
        if (DEBUG) console.log(`Price set to 0 due to invalid qm_price`);
      }
      if (DEBUG) console.log(`Price after GLAS_BOARD: ${price}`);
    } else {
      let defaultPrice = product.price * product.quantity;
      price += defaultPrice;
      if (DEBUG) console.log(`Default Price: ${defaultPrice}`);
      if (DEBUG) console.log(`Price after default case: ${price}`);
    }
  }
  if (
    product.min_quantity_surcharge &&
    product.selling_unit > 1 &&
    !ignoreMinQuantity
  ) {
    if (product.quantity < product.selling_unit) {
      let minQuantitySurcharge = 0;
      if (product.productType !== ProductTypes.POLE) {
        minQuantitySurcharge = product.min_quantity_surcharge * product.quantity;
      } else {
        minQuantitySurcharge = product.min_quantity_surcharge * product.length * product.quantity;
      }
      price += minQuantitySurcharge;
      if (DEBUG) console.log(`Min Quantity Surcharge: ${minQuantitySurcharge}`);
      if (DEBUG) console.log(`Price after min_quantity_surcharge: ${price}`);
    }
  }
  if (product.remainingStockDiscount) {
    price = price * (1 - product.remainingStockDiscount);
    if (DEBUG) console.log(`Price after remainingStockDiscount: ${price}`);
  }
  let discountCodePercentage = 0;
  if (store.getters.currentDiscountCode) {
    discountCodePercentage = store.getters.currentDiscountCode
      .discountPercentage
      ? store.getters.currentDiscountCode.discountPercentage
      : 0;
  }
  let absoluteDiscount = 0;
  if (store.getters.currentDiscountCode) {
    absoluteDiscount = store.getters.currentDiscountCode.absoluteDiscount
      ? store.getters.currentDiscountCode.absoluteDiscount
      : 0;
  }
  if (discountCodePercentage > 0 && !ignoreMinQuantity) {
    let finalPrice = price * user.multiplikator * (1 - discountCodePercentage);
    if (DEBUG) console.log(`Final price after discountCodePercentage: ${finalPrice}`);
    return finalPrice;
  } else if (absoluteDiscount > 0 && !ignoreMinQuantity) {
    let finalPrice = price * user.multiplikator - absoluteDiscount;
    if (DEBUG) console.log(`Final price after absoluteDiscount: ${finalPrice}`);
    return finalPrice;
  } else {
    if (product.discount && !ignoreMinQuantity) {
      let finalPrice = price * (1 - product.discount) * user.multiplikator;
      if (DEBUG) console.log(`Final price after product discount: ${finalPrice}`);
      return finalPrice;
    } else {
      let finalPrice = price * user.multiplikator;
      if (DEBUG) console.log(`Final price: ${finalPrice}`);
      return finalPrice;
    }
  }
}

function parsePricingStrings(pricingString) {
  let prices = pricingString.split(",");

  return prices
    .map((price) => {
      let [dimensions, cost] = price.split(":");
      let [width, height] = dimensions.split("x");
      return {
        height: parseFloat(height),
        width: parseFloat(width),
        price: parseFloat(cost),
      };
    })
    .sort((a, b) => {
      if (a.height - b.height === 0) {
        return a.width - b.width;
      } else {
        return a.height - b.height;
      }
    });
}

function normalizeDimensions(dimension) {
  return {
    width: Math.max(dimension.width, dimension.height),
    height: Math.min(dimension.width, dimension.height),
  };
}

function getPriceForDimensions(dimensions, product) {
  let normalizedProduct = normalizeDimensions(product);
  for (let i = 0; i < dimensions.length; i++) {
    let normalizedDimension = normalizeDimensions(dimensions[i]);

    if (
      normalizedProduct.height <= normalizedDimension.height &&
      normalizedProduct.width <= normalizedDimension.width
    ) {
      return dimensions[i].price;
    }
  }

  return dimensions[dimensions.length - 1].price;
}

function shiftToMinimumCalculationDimensions(product) {
  if (product instanceof SingleProduct) {
    if (product.productType === ProductTypes.CUT || product.productType === ProductTypes.FRAME) {
      if (product.width && product.height) {
        const totalDimensions = 2 * (product.width + product.height);
        
        if (totalDimensions < 100) {
          const scaleFactor = 100 / totalDimensions;
          product.width = product.width * scaleFactor;
          product.height = product.height * scaleFactor;
        }
      }
    }
  }
  return product;
}


export default calculatePrice;
