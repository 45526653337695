import ibis_logo from '../assets/images/IbiS_logo.png';
import bauhaus_logo from '../assets/images/bauhaus_logo.png';
import xxxl_logo from '../assets/images/xxxl_logo.png';
import weko_logo from '../assets/images/weko_logo.png';
import zurbruggen_logo from '../assets/images/zurbruggen_logo.png';
import ostermann_logo from '../assets/images/ostermann_logo.png';
import moebelhof_logo from '../assets/images/moebelhof_logo.jpg';
class AssetProvider {

    constructor() {
        if (this.instance) {
            return this.instance;
        }
        this.instance = this;
    }

    getLogoForUserType() {

        const userObject = JSON.parse(localStorage.getItem('ibisOnlinebestellungUserObject'));
        const customer_type = userObject?.customer_type;

        switch (customer_type) {
            case 'Fachhandel':
                return ibis_logo;
            case 'Bauhaus':
                return bauhaus_logo;
            case 'XXXL':
                return xxxl_logo;
            case 'Weko':
                return weko_logo;
            case 'Zurbrüggen':
                return zurbruggen_logo;
            case 'Ostermann':
                return ostermann_logo;
            case 'Möbelhof':
                return moebelhof_logo;
            default:
                return ibis_logo;
        }

    }

}

export default new AssetProvider();